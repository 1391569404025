@charset "UTF-8";

html{
	font-size: 16px;
	margin: 0;
	padding:0;
}
/*
* {
-webkit-appearance: none;
}*/


body{
	color:#444;
	line-height:1.6;
	text-align: center;
	z-index: 0;
	word-break : break-all;
  position: relative;
  z-index: 0;
  overflow-x:hidden;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
	-webkit-font-smoothing: subpixel-antialiased;
  background-repeat: no-repeat;
  -webkit-text-size-adjust: 100%;
	//font-family:A1 Mincho;
	//text-shadow: 0px 0px 1px rgba(0, 0, 0, .1);
	//-webkit-text-stroke: 0.1px #444;
	font-family:Ryumin Regular KL;
	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
	//font-family: "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro",Ryumin Regular KL,"ＭＳ Ｐ明朝","MS PMincho","HGS明朝E", serif;
}
.detail_body{
	margin-top: 70px !important;
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/font/NotoSerifCJKjp-Regular.otf') format('opentype');
}


body, div, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ul, ol, li,
brockquote, pre, table, caption, th, td, address,
form, fieldset, legend, object {
  margin: 0;
  padding: 0;
  font-weight:normal;
}
h1,h2,h3,h4,h5{
	margin: 0;
}

.content_out{
	padding-top: 80px;
	padding-bottom: 200px;
}

////////////　フォ－ム
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"]
{
	font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
}

li{
	list-style:none;
}

a{
	transition: all 0.1s;
	-webkit-transform: translatez(0);
	color:#333;
}

a:link,
a:visited,
a:active,
a:hover {
	outline: none;
	transition: all 0.1s;
	color:#333;
	text-decoration: none;
}


a:hover img{
	cursor:pointer;
}

a, ins {
  text-decoration: none;
}

address, em, cite, dfn, var {
  font-style: normal;
}

input, textarea {
  font-family: sans-serif;
  font-weight: normal;
}

img{
	vertical-align:bottom;
}

a img {
  border: none;
}

.m0a{margin:0 auto;}
.tc{text-align: center !important;}
.tl{text-align: left;}
.tr{text-align: right;}
.bbn{border-bottom: none !important;}
.fwb{font-weight: bold;}

.m0{margin:0;}
.mb0{ margin-bottom: 0 !important;}

.mt5{margin-top:5px;}
.mt10{margin-top:10px;}
.mt15{margin-top:15px;}
.mt20{margin-top:20px;}
.mt25{margin-top:25px;}
.mt30{margin-top:30px;}
.mt35{margin-top:35px;}
.mt40{margin-top:40px;}
.mt45{margin-top:45px;}
.mt50{margin-top:50px;}
.mt55{margin-top:55px;}
.mt60{margin-top:60px;}
.mt70{margin-top:70px;}
.mt80{margin-top:80px;}
.mt90{margin-top:90px;}
.mt100{margin-top:100px;}

.mb5{margin-bottom: 5px;}
.mb10{margin-bottom: 10px;}
.mb15{margin-bottom: 15px;}
.mb20{margin-bottom: 20px;}
.mb25{margin-bottom: 25px;}
.mb30{margin-bottom: 30px;}
.mb35{margin-bottom: 35px;}
.mb40{margin-bottom: 40px;}
.mb45{margin-bottom: 45px;}
.mb50{margin-bottom: 50px;}
.mb55{margin-bottom: 55px;}
.mb60{margin-bottom: 60px;}
.mb65{margin-bottom: 65px;}
.mb70{margin-bottom: 70px;}
.mb75{margin-bottom: 75px;}
.mb80{margin-bottom: 80px;}
.mb85{margin-bottom: 85px;}

.mr5{margin-right: 5px;}
.mr10{margin-right: 10px;}
.mr15{margin-right: 15px;}
.mr20{margin-right: 20px;}
.mr25{margin-right: 25px;}
.mr30{margin-right: 30px;}
.mr35{margin-right: 35px;}
.mr40{margin-right: 40px;}
.mr45{margin-right: 45px;}
.mr50{margin-right: 50px;}
.mr55{margin-right: 55px;}
.mr60{margin-right: 60px;}

.ml5{margin-left: 5px;}
.ml10{margin-left: 10px;}
.ml15{margin-left: 15px;}
.ml20{margin-left: 20px;}
.ml25{margin-left: 25px;}
.ml30{margin-left: 30px;}
.ml35{margin-left: 35px;}
.ml40{margin-left: 40px;}
.ml45{margin-left: 45px;}
.ml50{margin-left: 50px;}
.ml55{margin-left: 55px;}
.ml60{margin-left: 60px;}


.pd0{padding:0 !important;}
.pt5{padding-top:5px;}
.pt10{padding-top:10px;}
.pt15{padding-top:15px;}
.pt20{padding-top:20px;}
.pt25{padding-top:25px;}
.pt30{padding-top:30px;}
.pt35{padding-top:35px;}
.pt40{padding-top:40px;}
.pt45{padding-top:45px;}
.pt50{padding-top:50px;}
.pt55{padding-top:55px;}
.pt60{padding-top:60px;}
.pt70{padding-top:70px;}
.pt80{padding-top:80px;}
.pt90{padding-top:90px;}
.pt100{padding-top:100px;}

.pt1000{
	padding-top: 1000px;
}

.pb5{padding-bottom: 5px;}
.pb10{padding-bottom: 10px;}
.pb15{padding-bottom: 15px;}
.pb20{padding-bottom: 20px;}
.pb25{padding-bottom: 25px;}
.pb30{padding-bottom: 30px;}
.pb35{padding-bottom: 35px;}
.pb40{padding-bottom: 40px;}
.pb45{padding-bottom: 45px;}
.pb50{padding-bottom: 50px;}
.pb55{padding-bottom: 55px;}
.pb60{padding-bottom: 60px;}
.pb70{padding-bottom: 70px;}
.pb80{padding-bottom: 80px;}
.pb90{padding-bottom: 90px;}
.pb100{padding-bottom: 100px;}
.pb150{padding-bottom: 150px;}

.pr5{padding-right: 5px;}
.pr10{padding-right: 10px;}
.pr15{padding-right: 15px;}
.pr20{padding-right: 20px;}
.pr25{padding-right: 25px;}
.pr30{padding-right: 30px;}
.pr35{padding-right: 35px;}
.pr40{padding-right: 40px;}
.pr45{padding-right: 45px;}
.pr50{padding-right: 50px;}
.pr55{padding-right: 55px;}
.pr60{padding-right: 60px;}

.pl0{padding-left: 0px;}
.pl5{padding-left: 5px;}
.pl10{padding-left: 10px;}
.pl15{padding-left: 15px;}
.pl20{padding-left: 20px;}
.pl25{padding-left: 25px;}
.pl30{padding-left: 30px;}
.pl35{padding-left: 35px;}
.pl40{padding-left: 40px;}
.pl45{padding-left: 45px;}
.pl50{padding-left: 50px;}
.pl55{padding-left: 55px;}
.pl60{padding-left: 60px;}

@font-face {
  font-family: 'NotoSansCJKjp-Black';
  /*01*/
  src: url("/assets/fonts/NotoSansCJKjp-Black_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Bold';
  /*02*/
  src: url("/assets/fonts/NotoSansCJKjp-Bold_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-DemiLight';
  /*03*/
  src: url("/assets/fonts/NotoSansCJKjp-DemiLight_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Light';
  /*04*/
  src: url("/assets/fonts/NotoSansCJKjp-Light_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Medium';
  /*05*/
  src: url("/assets/fonts/NotoSansCJKjp-Medium_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Regular';
  /*06*/
  src: url("/assets/fonts/NotoSansCJKjp-Regular_min.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Thin';
  /*07*/
  src: url("/assets/fonts/NotoSansCJKjp-Thin_min.woff");
}
